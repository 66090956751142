import { CircularProgress, Container, Grid } from "@material-ui/core"
import React from "react"
import { Phone , History , Action } from "../../../models/Whitelist/Phone";
import mainStyles from '../../WorkerManagementComponent/WorkerManagementComponent.module.css'
import styles from '../AgentsComponent/styles.module.css'
import ClearIcon from '@material-ui/icons/Clear';
import { Add } from "@material-ui/icons";
import { BlueInverseBtn } from "../../Styled/StyledButtons";

interface IProps {
    history: History[]
}


const ActionsList = (props:IProps) => {
    return (
        <>
            {props.history?.map((history: History ) =>

                
                <Grid container>
                    <Grid className={mainStyles.tableDateCenter} item xs={3}>
                        {history.created_at !== null ? history.created_at : ''}
                    </Grid>
                    <Grid className={mainStyles.tableDateCenter} item xs={3}>
                        {history.action ? history.action!.action: ''}
                    </Grid>
                    <Grid className={mainStyles.tableDate} item xs={3}>
                        {history.action !== undefined ? history.action.comment: ''}
                    </Grid>
                    <Grid className={mainStyles.tableDateCenter} item xs={3}>
                        {history !== null ? history.created_by : ''}
                    </Grid>
                </Grid>

            )}
        </>   
    )
}
export default ActionsList;