import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Phone } from "../../models/Whitelist/Phone";
import { Button, Grid } from "@material-ui/core";
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import { DeleteForever, Edit } from "@material-ui/icons";
import AddToWhitelist from "./AddToWhitelist";
import RemoveFromWhitelist from "./RemoveFromWhitelist";

interface IProps {
    phone: Phone | null 
    toggleHandleRemove: () => void 
    toggleHandleUpdate: () => void 
    whitelistPhone: Phone | null
    showRemoveForm: boolean
    showAddForm: boolean
    handleShowRemoveForm: (phone: Phone) => void
    handleDelete: (phone: Phone) => void
    handleShowUpdateForm: (phone: Phone) => void
    handleUpdate: (phone: Phone) => void
}


const useStyles = makeStyles({
    tableEditButt: {
        border: '2px solid #3f73b0',
        color: '#3f73b0',
        background: 'none',
        fontSize: '14px',
        fontWeightRegular: '400',
        boxShadow: 'none',
        marginRight: '25px',
        '&:hover': {
            background: '#3f73b0',
            color: '#fff',
            boxShadow: 'none',
        },
    },
    tableRestoreButt: {
        border: '2px solid #61a15c',
        color: '#61a15c',
        background: 'none',
        fontSize: '14px',
        fontWeightRegular: '400',
        boxShadow: 'none',
        marginRight: '25px',
        '&:hover': {
            background: '#61a15c',
            color: '#fff',
            boxShadow: 'none',
        },
      },
    tableDeleteButt: {
        border: '2px solid rgb(201, 0, 0)',
        color: 'rgb(201, 0, 0)',
        background: 'none',
        fontSize: '14px',
        fontWeightRegular: '400',
        boxShadow: 'none',
        marginRight: '25px',
        '&:hover': {
            background: 'rgb(201, 0, 0)',
            color: '#fff',
            boxShadow: 'none',
        },
      },
});


const ControlPanelHistory = (props: IProps) => {

    const classes = useStyles();
    const deleted_at: string | undefined = props.phone?.deleted_at;
    
    return (
        <>
            { deleted_at == null ? 
                <Grid className={mainStyles.tableFunctionalityBox} container>
                    <Button className={classes.tableEditButt} onClick={()=>props.handleShowUpdateForm(props.phone!)} variant="outlined"><Edit /></Button>
                    <Button className={classes.tableDeleteButt} onClick={()=>props.handleShowRemoveForm(props.phone!)} variant="contained"><DeleteForever /></Button>
                </Grid> 
                : 
                <Grid className={mainStyles.tableFunctionalityBox} container spacing={2}>
                    <Button className={classes.tableRestoreButt} onClick={()=>props.handleShowUpdateForm(props.phone!)} variant="contained">restore</Button>
                </Grid>
            }

            <AddToWhitelist show={props.showAddForm} toggleHandle={props.toggleHandleUpdate} whitelistPhone={props.phone} handleSave={props.handleUpdate} />
            <RemoveFromWhitelist show={props.showRemoveForm} toggleHandle={props.toggleHandleRemove} whitelistPhone={props.phone} handleDelete={props.handleDelete} />
        </>
    )
}

export default ControlPanelHistory;