import React, { useEffect, useState } from "react";
import { IHistory } from ".";
import { Grid } from "@material-ui/core";
import { GreenBtn, RedBtn } from "../Styled/StyledButtons";
import { SpamFilterActions } from "../../actions/SpamFilterActions";
import { useCountdown } from "./useCountdown";
import Counter from "./Counter";
interface IProps {
    activeBlock: IHistory | null,
    changed: () => void
}

const BlockingPanel = ({ activeBlock, changed }: IProps) => {
    var targetDate = Date()
    var interval = 0
    activeBlock === null ? targetDate = Date() : targetDate = activeBlock.created_at
    activeBlock === null ? interval = 0 : interval = activeBlock.interval
    const [days, hours, minutes, seconds] = useCountdown(targetDate, interval);
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (activeBlock !== null && (days + hours + minutes + seconds <= 0)) {
            if (!refresh) {
                setRefresh(true)
                changed()
            }
        }
    }, [activeBlock, days, hours, minutes, seconds]);

    const onClickHandle = (value: number) => {
        SpamFilterActions.blockVoip(value)
            .then((data) => {
                changed()
            })
    }
    const onClickHandleUnblock = () => {
        SpamFilterActions.unblockBlockVoip()
            .then((data) => {
                changed()
            })
    }
    return (
        <>
            {(activeBlock === null) ?
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2>Block Voip Calls For:</h2>
                    </Grid>
                    <Grid item xs={2}><GreenBtn onClick={() => { onClickHandle(10) }}>10 mins</GreenBtn></Grid>
                    <Grid item xs={2}><GreenBtn onClick={() => { onClickHandle(20) }}>20 mins</GreenBtn></Grid>
                    <Grid item xs={2}><GreenBtn onClick={() => { onClickHandle(30) }}>30 mins</GreenBtn></Grid>
                    <Grid item xs={2}><GreenBtn onClick={() => { onClickHandle(60) }}>60 mins</GreenBtn></Grid>
                    <Grid item xs={2}><GreenBtn onClick={() => { onClickHandle(2000000000) }}>Infinite</GreenBtn></Grid>
                </Grid>
                :
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2>Blocked <Counter days={days} hours={hours} minutes={minutes} seconds={seconds} /></h2>
                        <p>Blocked at: {activeBlock.created_at} for {activeBlock.interval} mins</p>
                        <p>Blocked by: {activeBlock.created_by}</p>
                    </Grid>
                    <Grid item xs={3}><RedBtn onClick={() => { onClickHandleUnblock() }}>Unblock</RedBtn></Grid>
                </Grid>
            }
        </>
    )
}
export default BlockingPanel

function DateTime() {
    throw new Error("Function not implemented.");
}
