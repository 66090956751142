import { Button, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { DeleteForever, Edit } from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"
import React, { useEffect, useState } from "react"
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css'
import styles from '../AgentSkillsManagement/styles.module.css'
import { Pages } from "../../models/Pages"
import { Phone } from "../../models/Whitelist/Phone"

interface IProps {
  phones: any[]
  pagination: Pages | null
  handleChangePage: (page: number) => void
  handleDelete: (phone: Phone) => void
  handleUpdate: (phone: Phone) => void
}

const PhonesList = (props: IProps) => {
  return (
    <>
      <Container maxWidth="lg">
        <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Phone</TableCell>
                  <TableCell align="center">Comment</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                  <TableCell align="right">AddedBy</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={mainStyles.FlexColScroll}>
                {props.phones.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell align="left"><a href={"/whitelist-phone/"+ row.phone}> {row.phone}</a></TableCell>
                    <TableCell align="left">{row.comment}</TableCell>
                    <TableCell align="right">{row.updated_at}</TableCell>
                    <TableCell align="right">{row.created_by}</TableCell>
                    <TableCell align="right">
                    
                    {
                      row.deleted_at === null?
                        <>
                          <Button color="default" onClick={()=>props.handleUpdate(row)} ><Edit /></Button>
                          <Button color="secondary" onClick={()=>props.handleDelete(row)}><DeleteForever /></Button>
                        </>
                      :
                        <Button color="default" onClick={()=>props.handleUpdate(row)} > Restore</Button>
                    }
                     
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {(props.pagination !== null) ?
            <Grid item xs={12} >

              <Pagination
                className={styles.Pagination}
                count={props.pagination.LastPage}
                page={props.pagination.CurrentPage}
                onChange={(event: React.ChangeEvent<unknown> | null, newPage: number) => props.handleChangePage(newPage)}
                variant="outlined" shape="rounded" />
            </Grid> : ''
          }
        </Grid>
      </Container>

    </>
  )
}

export default PhonesList