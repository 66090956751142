import React, { useEffect, useState } from "react";
import { DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { useParams } from "react-router-dom";
import { Phone , History , Action } from "../../models/Whitelist/Phone";
import { RedBtn, RedInverseBtn } from "../Styled/StyledButtons";
import ActionsList from "./History";
import { WhiteListActions } from "../../actions/WhiteListActions";
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import { Backdrop, Box, Button, CircularProgress, Container, Dialog, Grid, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { BlueInverseBtn } from "../Styled/StyledButtons";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import styles from '../AgentSkillsManagement/styles.module.css';
import ControlPanelHistory from "./ControlPanelHistory";







const PhoneHistory = () => {
    let { phoneNumber } = useParams<{ phoneNumber: string }>();
    const [phone, setPhone] = useState<Phone | null>(null);
    const [backdrop, setBackdrop] = useState(true)
    const [errorAlert, setErrorAlert] = useState(false)
    const [errors, setErrors] = useState('')



    
    const [showRemoveForm, setShowRemoveForm] = useState(false)
    const [showAddForm, setShowAddForm] = useState(false)



    const [phoneToRemove, setPhoneToRemove] = useState<Phone | null>(null)
    const [phoneToUpdate, setPhoneToUpdate] = useState<Phone | null>(null)

    const loadPhone = () => {
        WhiteListActions.getPhone(phoneNumber).then((data: any) => {
            setBackdrop(false)
            setPhone(data)
        }).catch((error) => {
            setBackdrop(false)
            setErrors(error.message)
            console.log(error)
        })
    };

    useEffect(() => {
        loadPhone()
    }, []);

    const tel: string = phone?.phone || '';
    const comm: string = phone?.comment || '';


    const handleShowRemoveForm = (phone: Phone) => {
        setPhoneToRemove(phone)
        setShowRemoveForm(true)
    }

    const handleShowUpdateForm = (phone: Phone) => {
        setPhoneToUpdate(phone)
        setShowAddForm(true)
    }


     const handleDelete = (phone: Phone) => {
        WhiteListActions.deleteWhitelist(phone.id!, phone.comment)
            .then(() => loadPhone())
            .catch((error) => {
                setErrorAlert(true)
                setErrors(error.message)
            })
    }

    const handleSave = (phone: Phone) => {
            if (phone.id !== undefined) {
                WhiteListActions.updateWhitelist(phone.id, phone.comment)
                    .then(() => loadPhone())
                    .catch((error) => {
                        setErrorAlert(true)
                        setErrors(error.message)
                    })
            } else {
                WhiteListActions.createWhitelist(phone.phone, phone.comment)
                    .then(() => loadPhone())
                    .catch((error) => {
                        setErrorAlert(true)
                        setErrors(error.message)
                    })
            }
        }


    
    
    
    

    return (
        <>
            <Container className={mainStyles.mainContainer} maxWidth={false}>
                <Grid container spacing={1}>
                    <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
                        <Grid className={mainStyles.headInfoShortBox} container spacing={2}>
                            <Grid className={mainStyles.tableInfoShort} item xs={4}>
                                {tel}
                            </Grid>
                            <Grid className={mainStyles.tableInfoShort} item xs={4}>
                                {comm}
                            </Grid>
                        </Grid>

                        <ControlPanelHistory 
                            phone={phone} 
                            showRemoveForm={showRemoveForm} 
                            showAddForm={showAddForm} 
                            toggleHandleRemove={() => setShowRemoveForm(!showRemoveForm)} 
                            toggleHandleUpdate={() => setShowAddForm(!showAddForm)} 
                            whitelistPhone={phone} 
                            handleShowRemoveForm={handleShowRemoveForm} 
                            handleDelete={handleDelete}
                            handleShowUpdateForm={handleShowUpdateForm}
                            handleUpdate = {handleSave}
                        />



                        <Grid container spacing={4}>
                            <Grid className={mainStyles.tableTitle} item xs={3}>
                                Event Datetime 
                            </Grid>
                            <Grid className={mainStyles.tableTitle} item xs={3}>
                                Action  
                            </Grid>
                            <Grid className={mainStyles.tableTitle} item xs={3}>
                                Comment 
                            </Grid>
                            <Grid className={mainStyles.tableTitle} item xs={3}>
                                Created by
                            </Grid>
                            <ActionsList history={phone?.history || []}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        {/* 
                        <Grid item xs={12} md={3}>
                            {phone !== null && phone.deleted_at !== null ?
                                <BlueInverseBtn variant="contained" fullWidth={true} href={'/queue-create'}><Add />Restore</BlueInverseBtn>
                                :
                                ''
                            }
                        </Grid>
                        */}
                    </Grid>
                </Grid>
            </Container>
            <Snackbar open={errorAlert} autoHideDuration={5000} onClose={() => setErrorAlert(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Alert onClose={() => setErrorAlert(false)} severity="error" variant="filled">
                    Error<br />
                    <div className={styles.AlertError}>{errors}</div>
                </Alert>
            </Snackbar>
            <Backdrop className={styles.Backdrop} open={backdrop}>
                <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
                <p className={styles.BackdropProgress}>Loading...</p>
            </Backdrop>
        </>
    );
};

export default PhoneHistory